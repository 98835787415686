import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SiteDetailsFormValue, SiteDetailsStep } from './SiteDetailsStep';
import { WordpressInfoFormValue, WordpressInformationStep } from './WordpressInformationStep';
import { isManagedHosting, useGetToken } from 'utils/token';
import { useCreatePartnerSite, useCreateSite } from 'api/site';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import WizardDialog from 'component/new_design/base/dialogs/WizardDialog';
import { WizardFormActions, WizardFormContent } from '../../base/dialogs/WizardDialog';

const WOOCOMMERCE_PLUGIN = 'woocommerce';
const RECOMMENDED_PLUGINS = [
  'coming-soon',
  'google-site-kit',
  'optinmonster',
  'wpforms-lite',
  'wp-mail-smtp',
  'all-in-one-seo-pack',
];
const ATARIM_PLUGIN =
  'https://atarim.io/wp-content/uploads/atarim-client-interface-wpplugin-3.2.1.zip';

const PARTNER_SITE_DOMAIN = 'wpdns.site';

interface Props {
  readonly onCancel: () => void;
  readonly siteTemplateId?: string;
}

export const CreateSiteModal = ({ onCancel, siteTemplateId }: Props) => {
  const { t } = useTranslation();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [siteDetailsData, setSiteDetailsData] = useState<SiteDetailsFormValue | null>(null);

  const [isFormDirty, setIsFormDirty] = useState(false);

  const navigate = useNavigate();
  const token = useGetToken();
  const isManaged = isManagedHosting(token);
  const createPartnerSite = useCreatePartnerSite();
  const createSite = useCreateSite();

  const isSubmitting = createSite.isPending || createPartnerSite.isPending;

  const processSiteDetailsData = (data: SiteDetailsFormValue) => {
    setSiteDetailsData(data);
  };

  const processWordpressInfoData = async (data: WordpressInfoFormValue) => {
    if (!siteDetailsData) {
      throw new Error('Site details data is missing');
    }

    const installedPlugins = [];

    if (data.wooCommerce) {
      installedPlugins.push(WOOCOMMERCE_PLUGIN);
    }
    if (data.wpRecommendedPlugins) {
      installedPlugins.push(...RECOMMENDED_PLUGINS);
    }
    if (data.atarim) {
      installedPlugins.push(ATARIM_PLUGIN);
    }

    const baseData = {
      name: siteDetailsData.siteName,
      label: siteDetailsData.siteName,
      admin_username: data.username,
      admin_password: data.password,
      admin_email: data.email,
      location: siteDetailsData.isRestrictedLocation ? undefined : Number(siteDetailsData.location),
      restricted_location: siteDetailsData.isRestrictedLocation
        ? Number(siteDetailsData.location)
        : undefined,
      multisite: data.multisite,
      template_id: siteDetailsData.templateId || undefined,
      install_plugins: siteDetailsData.templateId ? undefined : installedPlugins.join(','),
    };

    let response;
    if (isManaged) {
      response = await createSite.mutateAsync(baseData);
    } else {
      response = await createPartnerSite.mutateAsync({ ...baseData, domain: PARTNER_SITE_DOMAIN });
    }
    navigate(`/sites/${response.data?.result?.id ?? ''}`);
  };

  const onStepComplete = async (data: SiteDetailsFormValue | WordpressInfoFormValue) => {
    if (activeStepIndex === 0) {
      processSiteDetailsData(data as SiteDetailsFormValue);
    } else {
      await processWordpressInfoData(data as WordpressInfoFormValue);
    }
    setActiveStepIndex(activeStepIndex + 1);
  };

  const steps = [
    {
      title: t('create_site_wizard_step_one_title'),
      description: t('create_site_wizard_step_one_description'),
      formDescription: t('create_site_wizard_step_one_form_title'),
      component: SiteDetailsStep,
    },
    {
      title: t('create_site_wizard_step_two_title'),
      description: t('create_site_wizard_step_two_description'),
      formDescription: t('create_site_wizard_step_two_form_title'),
      component: WordpressInformationStep,
    },
  ];

  const isLastStep = activeStepIndex === steps.length - 1;
  const activeStepFormId = `step-${activeStepIndex}`;
  const ActiveStepComponent = steps[activeStepIndex].component;
  const activeStepTitle = steps[activeStepIndex].title;
  const activeStepFormDescription = steps[activeStepIndex].formDescription;

  return (
    <WizardDialog
      activeStep={activeStepIndex + 1}
      onClose={onCancel}
      name="create-site"
      title={
        <Typography variant="body1" fontWeight="600" color="greys.900">
          {t('create_site')}
        </Typography>
      }
      steps={steps.map((step, index) => {
        return {
          index: index + 1,
          title: step.title,
          description: step.description,
        };
      })}
      stepNode={
        <>
          <WizardFormContent>
            <Typography variant="body1" fontWeight={600}>
              {activeStepTitle}
            </Typography>
            <Typography variant="body2" sx={{ color: 'greys.500' }} mb={4}>
              {activeStepFormDescription}
            </Typography>
            <ActiveStepComponent
              onComplete={onStepComplete}
              onFormDirty={setIsFormDirty}
              formId={activeStepFormId}
              hasTemplateId={!!siteDetailsData?.templateId}
              siteTemplateId={siteTemplateId}
            />
          </WizardFormContent>
          <WizardFormActions>
            <Stack gap={3} direction="row" flex="none">
              <LoadingButton
                size="large"
                type="submit"
                form={activeStepFormId}
                loading={isSubmitting}
                variant="contained"
              >
                {isLastStep ? t('create') : t('continue')}
              </LoadingButton>
              <Button size="large" onClick={onCancel}>
                {t('cancel')}
              </Button>
            </Stack>
          </WizardFormActions>
        </>
      }
      preventAccidentalClose={isFormDirty || activeStepIndex > 0}
    />
  );
};
